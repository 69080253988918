(function(){
  'use strict';

  angular
    .module('invites')
    .controller('InviteResponseCtrl', InviteResponseCtrl);

  /* Controller */
  InviteResponseCtrl.$inject = ['InviteService'];

  function InviteResponseCtrl(InviteService){
    var vm = this,
      static_url = (typeof STATIC_URL === 'undefined') ? '' : STATIC_URL;

    vm.declinePopoverIsOpen = false;
    vm.feedbackSubmitted = false;
    vm.working = false;
    vm.invite = null;
    // Something to improve it? https://github.com/GrumpyWizards/wizValidation
    vm.postcode_regex = /[a-z]{1,2}[0-9][a-z0-9]?\s?([0-9][a-z]{2})?/i;
    vm.declinePopover = {
      templateUrl: static_url + 'templates/invites/declinePopover.html'
    };
    vm.alerts = [];

    vm.init = function(invite_uuid, response){
      vm.invite = InviteService.get({uuid: invite_uuid}, setDefaultRadius);
      if(response === 'decline'){
        vm.declinePopoverIsOpen = true;
      }
    };

    function setDefaultRadius(){
      if(typeof vm.invite.decline === 'undefined' || vm.invite.decline === null){
        return;
      }
      if(vm.invite.decline.work_radius === null){
        vm.invite.decline.work_radius = '';
      }
      if(vm.invite.decline.home_radius === null){
        vm.invite.decline.home_radius = '';
      }
    }

    vm.isTooFar = function(){
      return isReasonEqual('too-far');
    };

    vm.isOptOut = function(){
      return isReasonEqual('not-interested');
    };
    
    function isReasonEqual(val){
      if(!vm.invite.decline){
        return false;
      }
      var reason = vm.invite.decline.reason;
      if(!reason){
        return false;
      }
      return (typeof reason === 'string') ? reason === val: reason[0] === val;
    }

    vm.togglePopover = function(){
      if(vm.declinePopoverIsOpen === false){
        vm.invite.$decline(setDefaultRadius, showError);
      }
      vm.declinePopoverIsOpen = !vm.declinePopoverIsOpen;
    };

    vm.submitFeedback = function(){
      vm.working = true;
      if(validInputs() === false){
        vm.working = false;
        return;
      }
      vm.invite.$sendFeedback(function(){
        vm.feedbackSubmitted = true;
        vm.working = false;
      }, showError);
    };

    function validInputs(){
      vm.alerts = [];
      if(typeof vm.invite.decline === 'undefined'){
        return true;
      }
      var valid = true;
      if(typeof vm.invite.decline.current_town === 'undefined'){
        valid = false;
        createError('Home postcode doesn\'t appear to be a valid postcode');
      }
      if(typeof vm.invite.decline.near_work === 'undefined'){
        valid = false;
        createError('Work postcode doesn\'t appear to be a valid postcode');
      }
      return valid;
    }

    vm.closeAlert = function(index){
      vm.alerts.splice(index, 1);
    };

    var showError = function(httpResponse){
      // Could be improved by something like this: http://stackoverflow.com/a/24000804/2261637
      var msg = httpResponse.statusText;
      if(httpResponse.status === -1){
        msg = 'No internet detected :( ...';
      }
      if ((httpResponse.status >= 400) && (httpResponse.status < 500)) {
        msg = 'Something is not right... Please try to log out and log in again.';
      }
      if ((httpResponse.status >= 500) && (httpResponse.status < 600)) {
        msg = 'We are sorry, we just encountered error. ';
        msg += 'We\'ve been alerted and will look into it, you can try again later.';
      }
      if(msg === ''){
        msg = 'We are sorry, something unexpected happened. We are having a look, please try again later.';
      }
      createError(msg);
      vm.working = false;
    };

    function createError(msg){
      vm.alerts.push({ type: 'danger', msg: msg});
    }
  }

})();

