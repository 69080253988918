(function () {
  'use strict';

  angular
    .module('common.filters', [])
    .filter('limitToWords', limitToWords)
    .filter('stripNumbers', stripNumbers);
  
  function limitToWords() {
    return function (input, wordsLimit, truncateIndicator) {
      if (!input || typeof input != 'string') {
        return input;
      }
      if (!truncateIndicator) {
        truncateIndicator = '...';
      }
      var wordsArray = input.split(' ');
      if (wordsArray.length <= wordsLimit) {
        return input;
      } else {
        return wordsArray.splice(0, wordsLimit).join(' ') + truncateIndicator;
      }
    };
  }

  function stripNumbers() {
    return function (input) {
      if (!input || typeof input != 'string') {
        return input;
      }
      return input.replace(/\d/g, '').trim(' ');
    };
  }

})();
