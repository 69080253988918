(function () {
  'use strict';

  angular
    .module('search')
    .factory('checkboxRangeFilter', checkboxRangeFilter);

  checkboxRangeFilter.$inject = ['$location', '$filter'];

  function checkboxRangeFilter($location, $filter) {
    return function (filterObj) {
      var vm = {
        filterObj: filterObj,
        category: filterObj.slug,
        title: filterObj.title,
        showMax: filterObj.show_max,
        showMoreSlice: filterObj.show_max - 2,
        model: {},
        // Used to control which directive and template to use
        type: 'checkbox',
        validOptions: []
      };

      angular.forEach(filterObj.options, function (option) {
        option.doc_count = null;
        vm.model[option.slug] = false;
      });

      var value = $location.search()[vm.category];
      if (typeof value === 'string') {
        vm.model[value] = true;
      } else {
        angular.forEach(value, function (v) {
          vm.model[v] = true;
        });
      }

      /**
       * Build the GET params value for this specific filter:
       * Value is the list of all checked options
       */
      vm.getParams = function () {
        var params = [];
        angular.forEach(vm.model, function (filterValue, filterName) {
          if (filterValue) {
            params.push(filterName);
          }
        });
        return params;
      };

      /**
       * Update display of filter based on aggregation returned in the results
       */
      vm.updateFilter = function (aggregation) {
        var buckets = aggregation.buckets;
        angular.forEach(vm.filterObj.options, function (option) {
          var option_bucket;
          if (vm.filterObj.keyed) {
            option_bucket = buckets[option.slug];
            option.doc_count = option_bucket.doc_count;
          } else {
            option_bucket = $filter('filter')(buckets, {key: option.slug});
            if (option_bucket.length > 0) {
              option.doc_count = option_bucket[0].doc_count;
            } else {
              option.doc_count = 0;
            }
          }
        });
        // Valid options are only doc count > 0 OR ticked
        vm.validOptions = $filter('filter')(vm.filterObj.options, function (value) {
          return value.doc_count > 0 || vm.model[value.slug];
        });
      };

      /**
       * Update choices in the filter before loading the results to make sure
       * the selection is consistent
       * Not used in this context, declared just to keep the API consistent
       */
      vm.onSet = function(item) {
        angular.forEach(vm.model, function (item_value, item_name) {
          if (item_name !== item) {
            vm.model[item_name] = false;
          }
        });
      };

      return vm;

    };
  }

})();
