(function () {
  'use strict';

  angular
    .module('search')
    .controller('ShortListCtrl', ShortListCtrl);

  ShortListCtrl.$inject = ['$scope', '$cookies'];

  function ShortListCtrl($scope, $cookies) {
    var vm = this;
    var genderPreferenceKey = 'genderPreference';
    vm.shortList = [];
    vm.genderPreference = null;
    vm.eventType = 'Event';

    vm.init = function(shortlist, eventType, genderPreferenceCookie) {
      vm.eventType = eventType;
      genderPreferenceKey = genderPreferenceCookie;
      for (var idx in shortlist) {
        vm.shortList.push({id: shortlist[idx]});
      }
    };


    $scope.addSpeaker = function(speaker){
      if(!$scope.isShortlisted(speaker)){
        if(!$scope.eventFull()) {
          vm.shortList.push(speaker);
        }
      }
    };

    $scope.removeSpeaker = function(speaker){
      for(var idx in vm.shortList){
        if(vm.shortList[idx].id === speaker.id){
          vm.shortList.splice(idx, 1);
          return;
        }
      }
    };

    $scope.toggleSpeaker = function(speaker){
      $scope.isShortlisted(speaker) ? $scope.removeSpeaker(speaker) : $scope.addSpeaker(speaker);
    };

    $scope.isShortlisted = function(speaker){
      if(!speaker){
        return false;
      }
      for (var idx in vm.shortList) {
        if(vm.shortList[idx].id  === speaker.id){
          return true;
        }
      }
      return false;
    };

    $scope.maxSpeakers = function(){
      return 12;
    };

    $scope.minSpeakers = function(){
      // This hard-coded magic number logic is reproduced at founders/events/tasks.py
      // until we can easily share backend config with front end more easily.
      return 3;
    };

    $scope.shortListCount = function(){
      return vm.shortList.length;
    };

    $scope.enoughSpeakers = function(){
      return $scope.shortListCount() >= $scope.minSpeakers();
    };

    $scope.eventFull = function(){
      return $scope.shortListCount() == $scope.maxSpeakers();
    };

    $scope.tooManySpeakers = function(){
      return $scope.shortListCount() > $scope.maxSpeakers();
    };

    $scope.shortlistedClass = function(speaker){
      return $scope.isShortlisted(speaker) ? 'event-selected': '';
    };

    $scope.isGenderOnly = function(gender){
      if($scope.shortListCount() === 0){
        return false;
      }
      for(var idx in vm.shortList){
        var s = vm.shortList[idx];
        if(!s.gender || s.gender != gender){
          return false;
        }
      }
      return true;
    };

    $scope.setGenderPreference = function(gender){
      vm.genderPreference = gender;
      if(gender === null){
        gender = '';
      }
      $cookies.put(genderPreferenceKey, gender, {path: '/'});
    };

  }

})();
