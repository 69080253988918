(function () {
  "use strict";

  angular
    .module('insights')
    .controller('EnterpriseListController', EnterpriseListController);

  EnterpriseListController.$inject = [
    '$scope',
    '$http',
    'ClaimSchoolService',
    ]

  function EnterpriseListController($scope, $http, ClaimSchoolService) {
    var vm = this;
    vm.fetch_list = function(role) {
      vm.list = ClaimSchoolService.pick_list(role)
      return vm.list
    }
    vm.get_list = function(role) {
      return ClaimSchoolService.list(role)
    }
  }
})();
