(function(){
  'use strict';

  angular
    .module('home', [])
    .controller('BackgroundHeaderCtrl', BackgroundHeaderCtrl);

  BackgroundHeaderCtrl.$inject = ['$scope', '$interval'];

  function BackgroundHeaderCtrl($scope, $interval){
    var vm = this;

    vm.backgroundClasses = [
      'site-header-bg-b1',
      'site-header-bg-b3',
      'site-header-bg-b2'
    ];

    vm.current = 0;
    vm.currentClass = vm.backgroundClasses[vm.current];
    vm.interval = 6000;

    function nextBackground() {
      vm.currentClass = vm.backgroundClasses[vm.current = ++vm.current % vm.backgroundClasses.length];
    }

    var stop = $interval(nextBackground, vm.interval);

    $scope.$on('$destroy', function() {
      // Make sure that the interval is destroyed too
      if (angular.isDefined(stop)) {
        $interval.cancel(stop);
      }
    });

  }

})();
