(function () {
  "use strict";


  angular
    .module('notifications', [])
    .controller('NewsletterManagementCtrl', NewsletterManagementCtrl);

  NewsletterManagementCtrl.$inject = [
    '$scope',
    '$http',
    '$cookies',
    ]

  function NewsletterManagementCtrl($scope, $http, $cookies) {
      $scope.api_mapping = {} // this gets populated by django templates...

      $scope.toggle = function(id) {
          $http.put($scope.api_mapping[id], {}, {
              headers: {'X-CSRFToken': $cookies.get('csrftoken')},
              params: {'uuid': $scope.uuid}
          }).then(
              function (resp) {

              }
          )
      }

      $scope.unsub_nl = function() {
          $http.post('/newsletters/unsub/', {}, {
              headers: {'X-CSRFToken': $cookies.get('csrftoken')},
              params: {'uuid': $scope.uuid}
          }).then(
              function (resp) {
                  angular.forEach($scope.state, function(value, key){
                      this[key] = false
                  }, $scope.state)
              }
          )
      }
  }

})();
