(function() {
  'use strict';

  angular
    .module('common.sharing')
    .directive('socialShareGroup', socialShareGroup);
  
  socialShareGroup.$inject = ['$document'];

  function socialShareGroup($document) {
    var static_url = (typeof STATIC_URL === 'undefined') ? '' : STATIC_URL;
    return {
      restrict: 'E',
      templateUrl: static_url + 'templates/common/social-share-group.template.html',
      scope: {
        pageUrl: '@',
        btnText: '@',
        twitterMsg: '@',
        otherMsg: '@',
        btnSizeMod: '@',
        postTitle: '@',
        postImage: '@',
        facebookAppId: '@'
      },
      link: link,
      controller: 'ShareController',
      controllerAs: 'vm',
      bindToController: true
    };

    function link (scope, element) {

      var toggleShareDisplay = function (event) {
        scope.vm.btnText = (typeof scope.vm.btnText === 'undefined') ? 'Share' : scope.vm.btnText;
        var isChild = element[0].contains(event.target);
        var isSelf = element[0] == event.target;
        scope.$apply(function(){
          scope.vm.showBtnGroup = isChild || isSelf;
        });
      };

      $document.bind('click', toggleShareDisplay);
    }
  }

})();
