(function () {
  'use strict';

  angular
    .module('notifications.dashboard', [])
    .controller('MarkReadCtrl', MarkReadCtrl);

  MarkReadCtrl.$inject = ['$http', '$log'];

  function MarkReadCtrl($http, $log) {
    var vm = this;

    vm.isRead = false;

    vm.doMark = function(url) {
      $http.post(url).then(function (response) {
        vm.isRead = true;
      }, function(response){
        $log.error(response);
      });
    };

  }

})();
