(function () {
  'use strict';

  angular
    .module('partners')
    .controller('PartnerCardCtrl', PartnerCardCtrl);

  function PartnerCardCtrl() {
    var vm = this;

    this.$onInit = function () {
      vm.ordering = '-num_encounters';
      vm.partner_type = 'partner_type';
      vm.partnerlist = [];

      vm.init = function (partners_json) {
        vm.partnerlist = partners_json;
      };

      vm.seeOrdering = function () {
        vm.ordering = '-num_encounters';
      };

      vm.isSeeOrdered = function () {
        return vm.ordering === '-num_encounters';
      };

      vm.nameOrdering = function () {
        vm.ordering = 'name';
      };

      vm.isNameOrdered = function () {
        return vm.ordering === 'name';
      };

      function checkCorporatePartner(partner){
        return partner.partner_type === 'corporate_partner';
      }
      vm.existsCorporatePartners = function () {
        return vm.partnerlist.filter(checkCorporatePartner).length === 0
      }


      function checkTrustsFoundations(partner){
        return partner.partner_type === 'trusts_foundations';
      }
      vm.existsTrustsFoundations = function () {
        return vm.partnerlist.filter(checkTrustsFoundations).length === 0
      }


      function checkLocalCentralGovts(partner){
        return partner.partner_type === 'local_central_govts';
      }
      vm.existsLocalCentralGovts = function () {
        return vm.partnerlist.filter(checkLocalCentralGovts).length === 0
      }


      function checkDeliverySuppPartners(partner){
        return partner.partner_type === 'delivery_supporting_partners';
      }
      vm.existsDeliverySuppPartners = function () {
        return vm.partnerlist.filter(checkDeliverySuppPartners).length === 0
      }



    }
  }
}());
