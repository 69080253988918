(function () {
  'use strict';
  var static_url = (typeof STATIC_URL === 'undefined') ? '' : STATIC_URL;

  angular
    .module('search')
    .directive('searchFacets', searchFacets);

  function searchFacets() {
    return {
      restrict: 'E',
      templateUrl: function(elem, attrs) {
        return static_url + 'templates/search/search-facets-' + attrs.filterType + '.template.html';
      },
      scope: {
        filter: '=filterObj',
        resultCtrl: '='
      }
    };

  }

})();
