/* global Highcharts */
(function () {
  'use strict';

  angular
    .module('charts')
    .service('HighchartsSvc', HighchartsSvc);

  HighchartsSvc.$inject = [];

  function HighchartsSvc() {
    var svc = this;
    svc.hc = Highcharts;

    svc.hc.setOptions({
      colors: ['#66AA00', '#DD4477', '#B82E2E', '#0099C6', '#990099', '#316395', '#994499', '#109618', '#FF9900', '#DC3911', '#3366CC'],
      lang: {
        thousandsSep: ','
      }
    });

    svc.chart = function (elem, options) {
      return new svc.hc.chart(elem, options);
    };
  }

})();
