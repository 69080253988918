(function() {
  'use strict';

  angular
    .module('surveys')
    .controller('RatingCtrl', RatingCtrl);

  RatingCtrl.$inject = ['$http', '$location', '$filter', '$window'];

  function RatingCtrl($http, $location, $filter, $window) {
    var ctrl = this;

    ctrl.toRate = [];
    ctrl.ratingNow = emptyRatingNow();
    ctrl.beingRated = -1;
    ctrl.detailedFeedback = false;
    ctrl.maxVisibleToReview = 3;
    ctrl.content_type_id = 1;
    ctrl.working = false;
    ctrl.initiated = false;
    ctrl.other_count = 0;
    ctrl.quick_rating = false;

    ctrl.init = function(content_type_id, api_list_url, other_count) {
      ctrl.content_type_id = content_type_id;
      ctrl.other_count = (typeof other_count === 'undefined') ? 0 : other_count;
      $http.get(api_list_url).then(
        function(response){
          ctrl.toRate = response.data;
          ctrl.nextItem($location.search());
          ctrl.initiated = true;
        },
        function(){
          ctrl.initiated = true;
        }
      );
    };

    /**
     * Used for quick rating of a single rated_object
     * @param content_type_id used to build the URL when submitting rating
     * @param rated_object
     */
    ctrl.initSingle = function(content_type_id, rated_object) {
      ctrl.content_type_id = content_type_id;
      ctrl.beingRated = rated_object;
      ctrl.quick_rating = true;
      var searchObj = $location.search();
      if(searchObj.rating) {
        ctrl.ratingNow.rating_value = Number(searchObj.rating);
      }
      ctrl.initiated = true;
    };

    /**
     * @returns the object considered as empty rating
     */
    function emptyRatingNow(){
      return {
        rating_value: null,
        comment: '',
        would_recommend: null
      };
    }

    ctrl.notRatedYet = function(){
      return ctrl.ratingNow.rating_value === null;
    };

    ctrl.toggleDetailed = function(){
      ctrl.detailedFeedback = !ctrl.detailedFeedback;
    };

    ctrl.isBadlyRated = function(){
      if(ctrl.ratingNow.rating_value === null || ctrl.ratingNow.rating_value > 2){
        return false;
      }
      return true;
    };

    ctrl.isDetailFeedback = function(){
      return ctrl.detailedFeedback === true || ctrl.isBadlyRated();
    };

    ctrl.hasWaiting = function(){
      return ctrl.toRate.length > 0;
    };

    ctrl.hasHidden = function(){
      return ctrl.toRate.length > ctrl.maxVisibleToReview;
    };

    ctrl.hiddenCount = function(){
      if(ctrl.hasHidden()){
        return ctrl.toRate.length - ctrl.maxVisibleToReview + 1;
      }
      return 0;
    };

    ctrl.toShowCount = function(){
      return ctrl.toRate.length - ctrl.hiddenCount();
    };

    ctrl.nextItem = function(searchObj){
      ctrl.ratingNow = emptyRatingNow();
      if(searchObj) {
        if(searchObj.rating && searchObj.id) {
          var candidates = $filter('filter')(ctrl.toRate, function(toRateObj){
            return String(toRateObj.id) === searchObj.id;
          });
          if(candidates.length > 0) {
            ctrl.beingRated = candidates[0];
            ctrl.ratingNow.rating_value = Number(searchObj.rating);
            return;
          }
        }
      }
      if(ctrl.toRate.length > 0){
        ctrl.beingRated = ctrl.toRate.shift();
      } else {
        ctrl.beingRated = null;
      }
    };

    ctrl.submitReview = function(){
      if(ctrl.ratingNow.would_recommend === null){
        delete ctrl.ratingNow.would_recommend;
      }
      submitOrSkip(ctrl.ratingNow);
    };

    ctrl.skipReview = function(){
      submitOrSkip({});
    };

    function submitOrSkip(data){
      ctrl.working = true;
      var reviewUrl;
      if(ctrl.quick_rating){
        reviewUrl = '/reviews/quick-add/' + ctrl.beingRated.user_uid + '/' + ctrl.content_type_id + '/' + ctrl.beingRated.uuid;
      } else {
        reviewUrl = '/reviews/add/' + ctrl.content_type_id + '/' + ctrl.beingRated.id;
      }
      $http.post(reviewUrl, data).then(
        function(){
          ctrl.nextItem();
          ctrl.working = false;
        },
        function(){
          // A bit dumb, but most likely event already rated
          $window.location.reload();
          ctrl.working = false;
        }
      );
    }

    ctrl.noOneToRate = function(){
      return ctrl.beingRated === null;
    };

    ctrl.unratedTotalCountStr = function() {
      return ctrl.unratedCountStr(ctrl.other_count);
    };

    ctrl.unratedCountStr = function(offset){
      if(ctrl.noOneToRate()){
        if(!offset){
          return '';
        }
      } else {
        if(!offset){
          offset = 1;
        } else {
          offset += 1;
        }
      }
      return '(' + (ctrl.toRate.length + offset) + ') ';
    };

    ctrl.setCurrentItem = function(item_index){
      if(item_index === null || item_index === undefined || item_index < 0 || item_index >= ctrl.toRate.length){
        return;
      }
      if(item_index > 0 && item_index === ctrl.toRate.length - 1){
        // Last non-0 index: use pop()
        var tmp = ctrl.toRate.pop();
        ctrl.toRate.push(ctrl.beingRated);
        ctrl.beingRated = tmp;
      } else {
        ctrl.toRate.push(ctrl.beingRated);
        if(item_index === 0){
          // First item: use shift()
          ctrl.beingRated = ctrl.toRate.shift();
        } else {
          // Otherwise, splice()
          ctrl.beingRated = ctrl.toRate.splice(item_index, 1)[0];
        }
      }
    };

  }

})();
