/* global GA_ACCOUNT */
(function () {
  'use strict';

  // https://docs.bugsnag.com/platforms/browsers/angular/#legacy-angular-support
  angular
    .module('exceptionOverride', [])
    .factory('$exceptionHandler', function () {
        return function (exception, cause) {
          bugsnagClient.notify(exception, {
            beforeSend: function (report) {
              report.updateMetaData('angular', { cause: cause })
            }
          })
        }
      });

  var f4sApp = angular.module('f4sApp', [
    'ui.bootstrap',
    'ngAnimate',
    'ngRoute',
    'angular-google-analytics',
    'ngCookies',
    'smoothScroll',
    'common.filters',
    'common.slide',
    'common.sharing',
    'common.cookie-consent',
    'donate',
    'home',
    'notifications',
    'notifications.alerts',
    'notifications.dashboard',
    'alerts',
    'invites',
    'events',
    'sticky',
    'search',
    'search.gridlist',
    'leaders.profile',
    'insights',
    'surveys',
    'charts',
    'partners',
    'companies'
  ]);
  f4sApp.config(configure);

  configure.$inject = [
    '$sceDelegateProvider',
    '$locationProvider',
    '$httpProvider',
    '$resourceProvider',
    'AnalyticsProvider'
  ];

  function configure(
    $sceDelegateProvider,
    $locationProvider,
    $httpProvider,
    $resourceProvider,
    AnalyticsProvider
  ) {
    $sceDelegateProvider.resourceUrlWhitelist([
      'self',
      'https://*.founders4schools.org.uk/**',
      'https://*assets.founders4schools.org.uk/**',
      'https://*static.founders4schools.org.uk/**',
      'https://*.cloudfront.net/**'
    ]);

    $locationProvider.html5Mode.enabled = true;
    // $httpProvider.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
    $httpProvider.defaults.xsrfCookieName = 'csrftoken';
    $httpProvider.defaults.xsrfHeaderName = 'X-CSRFToken';
    $resourceProvider.defaults.stripTrailingSlashes = false;
    var ga_account = (typeof GA_ACCOUNT === 'undefined') ? '' : GA_ACCOUNT;
    AnalyticsProvider.setAccount(ga_account);
  }

})();
