/*
 * http://stackoverflow.com/questions/16310298/if-a-ngsrc-path-resolves-to-a-404-is-there-a-way-to-fallback-to-a-default
 *
 * Basic err-src directive to provide a fallback image if the one provided as ng-src
 * leads to a 404 error
 */

(function() {
  'use strict';

  angular.module('common.errsrc', [])
    .directive('errSrc', function () {
      return {
        restrict: 'A',
        link: function (scope, element, attrs) {
          element.bind('error', function () {
            if (attrs.src != attrs.errSrc) {
              attrs.$set('src', attrs.errSrc);
            }
          });

          attrs.$observe('ngSrc', function(value) {
            if (!value) {
              attrs.$set('src', attrs.errSrc);
            }
          });
        }
      };
    });
})();
