"use strict";

angular
  .module('common.cookie-consent', [])
  .controller('CookieConsentController', CookieConsentController);

CookieConsentController.$inject = [
  "$scope",
  "$cookies"
]

/**
 * Controls the cookie consent modal.
 * The modal is displayed if there is no cookieConsent cookie set.
 * When "Accept all" or "Accept necessary" button is clicked, cookieConsent cookie is set
 * respectively to "all" or "necessary".
 */
function CookieConsentController($scope, $cookies) {
  $scope.show = $cookies.get("cookieConsent") === undefined;

  /** GDPR or ePD does not specify consent expiry date. 6 months seems reasonable. */
  var consentExpireDate = new Date();
  consentExpireDate.setMonth(consentExpireDate.getMonth() + 6);

  var cookieSettings = {
    "expires": consentExpireDate,
    "samesite": "strict"
  }

  $scope.acceptAll = function () {
    $cookies.put("cookieConsent", "all", cookieSettings);
    $scope.show = false;
    loadGoogleAnalytics();
  }

  $scope.acceptNecessary = function () {
    $cookies.put("cookieConsent", "necessary", cookieSettings);
    $scope.show = false;
  }
}
