(function () {
  'use strict';

  angular
    .module('charts')
    .controller('ChartModalCtrl', ChartModalCtrl)
    .controller('ChartModalInstanceCtrl', ChartModalInstanceCtrl);

  ChartModalCtrl.$inject = ['$uibModal'];

  function ChartModalCtrl($uibModal) {
    var vm = this;
    var static_url = (typeof STATIC_URL === 'undefined') ? '' : STATIC_URL;

    vm.open = function (metricName, metricUnit, slug, section) {

      $uibModal.open({
        size: 'xlg',
        templateUrl: static_url + 'templates/charts/doubleChartsModal.html',
        controller: 'ChartModalInstanceCtrl',
        controllerAs: 'vm',
        resolve: {
          metricName: function () {
            return metricName;
          },
          metricUnit: function () {
            return metricUnit;
          },
          slug: function() {
            return slug;
          },
          section: function() {
            return section;
          }
        }
      });
    };

  }

  var titles = {
    count: 'Total young people',
    encounters: 'Total Student-Employer Encounters'
  };
  var yTitles = {
    count: 'Young people',
    encounters: 'Student-Employer Encounters'
  };

  // Modal instance
  ChartModalInstanceCtrl.$inject = [
    '$scope',
    '$uibModalInstance',
    '$resource',
    'metricName',
    'metricUnit',
    'slug',
    'section'
  ];

  function ChartModalInstanceCtrl($scope, $uibModalInstance, $resource, metricName, metricUnit, slug, section) {
    var vm = this;
    vm.confirm = $uibModalInstance.close;
    vm.cancel = $uibModalInstance.dismiss;
    // Initialize what we can
    vm.leftChart = {};
    vm.rightChart = {yTitle: yTitles[metricUnit]};
    vm.modalTitle = titles[metricUnit];
    vm.timeframes = [
      {value: 'week', label: 'Weekly'},
      {value: 'month', label: 'Monthly'},
      {value: 'quarter', label: 'Quarterly'},
      {value: 'year', label: 'Yearly'}
    ];
    vm.timeframe = vm.timeframes[2]; // Quarterly by default

    var paramsDefaults = {metricType: metricName, metricUnit: metricUnit};
    if(slug) {
      paramsDefaults.slug = slug;
    }
    if(section) {
      paramsDefaults.section = section;
    }
    // Resource to query chart endpoints
    vm.chartResource = $resource(
      // URL to query
      '/insights/schools/:section/:slug/chart/:chartType/:metricType/:metricUnit/',
      // Default parameters
      paramsDefaults,
      // Custom actions
      {
        fetchPieData: {method: 'GET', params: {chartType: 'pie'}, isArray: true, cache: true},
        fetchColumnsData: {method: 'GET', params: {chartType: 'columns'}, cache: true}
      }
    );
    vm.rightChart.extraParams = {timeframe: vm.timeframe.value};

    switch (metricName) {
    case 'ages':
      vm.leftChart.title = vm.modalTitle + ' - By age (cumulative)';
      vm.rightChart.title = vm.modalTitle + ' - By age (over time)';
      break;
    case 'event-type':
      vm.leftChart.title = vm.modalTitle + ' - By type (cumulative)';
      vm.rightChart.title = vm.modalTitle + ' - By type (over time)';
      break;
    }

    vm.timeFrameChanged = function() {
      vm.rightChart.extraParams = {timeframe: vm.timeframe.value};
    };

  }

})();
