(function () {
  'use strict';

  angular
    .module('search')
    .controller('SearchResultsCtrl', SearchResultsCtrl);

  SearchResultsCtrl.$inject = [
    'SearchService',
    'checkboxFilter',
    'rangeFilter',
    'checkboxRangeFilter',
    '$filter',
    '$timeout',
    '$scope',
    '$location'
  ];

  function SearchResultsCtrl(
    SearchService,
    checkboxFilter,
    rangeFilter,
    checkboxRangeFilter,
    $filter,
    $timeout,
    $scope,
    $location
  ) {
    var vm = this;

    vm.search_uuid = null;
    vm.results = null;
    vm.totalCount = null;
    vm.unfilteredTotal = null;
    vm.filteredResults = null;
    vm.busy = false;
    vm.filters = [];
    vm.currentPage = null;
    vm.pageKey = 'page';
    vm.perPage = 12;
    vm.last_checked_category = null;
    vm.typeahead = null;

    vm.init = function (searchUuid, totalCount, filtersJSON) {
      vm.search_uuid = searchUuid;
      vm.unfilteredTotal = totalCount;
      angular.forEach(filtersJSON, function (filterObj) {
        var filter;
        var filterClass = {
          checkbox: checkboxFilter,
          dropdown: rangeFilter,
          checkboxrange: checkboxRangeFilter
        }[filterObj.type];
        filter = filterClass(filterObj);
        vm.filters.push(filter);
      });
      vm.currentPage = $location.search()[vm.pageKey];
      vm.loadResults();
    };

    vm.loadResults = function () {
      if (vm.busy) return;
      vm.busy = true;
      var params = buildParamsObj();
      SearchService.get(params, setResultsCallback, showErrorCallback);
    };

    vm.showMore = function() {
      vm.perPage = vm.perPage + 12;
      vm.loadResults();
    };

    function buildParamsObj() {
      var params = {};
      if (vm.pageKey) {
        params[vm.pageKey] = vm.currentPage;
      }
      if (vm.perPage) {
        params['results'] = vm.perPage;
      }
      angular.forEach(vm.filters, function (filter) {
        var filter_params = filter.getParams();
        if (filter_params) {
          params[filter.category] = filter_params;
        }
      });
      $location.search(params);
      params.uuid = vm.search_uuid;
      return params;
    }

    function setResultsCallback(data) {
      vm.totalCount = data.total;
      vm.pageKey = data.page_kwarg;
      vm.currentPage = data.page;
      vm.results = data.object_list;
      updateFiltersCount(data.aggregations);
      vm.busy = false;
    }

    function updateFiltersCount(aggregations) {
      angular.forEach(vm.filters, function (filter) {
        if (filter.category === vm.last_checked_category) {
          return;
        }
        filter.updateFilter(aggregations[filter.category]);
      });
    }

    function showErrorCallback() {
      vm.busy = false;
    }

    vm.toggleFilter = function (category, item, isTypeAhead) {
      vm.currentPage = 1;
      for (var idx = 0; idx < vm.filters.length; idx++) {
        var filter = vm.filters[idx];
        if (filter.category === category) {
          if (filter.model[item]) {
            // Just been ticked? If using type-ahead, don't record
            // the category changed and refresh all categories
            if (isTypeAhead === true) {
              vm.last_checked_category = null;
            } else {
              vm.last_checked_category = category;
            }
            filter.onSet(item);
          } else {
            // Just been un-checked
            vm.last_checked_category = null;
          }
        }
      }
      vm.loadResults();
    };

    /*
     * Callback when a filter is chosen using the type-ahead functionality
     * we need to update the corresponding model and query the back-end
     * with the appropriate filtering
     */
    vm.typeaheadOnSelect = function (filter, item) {
      filter.model[item.slug] = !filter.model[item.slug];
      vm.toggleFilter(filter.category, item.slug, true);
    };

  }

})();
