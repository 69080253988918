(function () {
  "use strict";

  angular
    .module('insights')
    .service('ClaimSchoolService', ClaimSchoolService);

  ClaimSchoolService.$inject = [
    '$resource',
    '$window',
    ]

  function ClaimSchoolService($resource, $window) {
    var ea_list = []
    var ec_list = []
    // this is very hacky... should be fixed
    var entity_slug = $window.location.pathname.split('/')[3];
    var school_slug = $window.location.pathname.split('/')[4];

    var adviser = $resource('/api/v1/users/adviser/:id?school=' + school_slug, {id:'@id'}, {
      update: {method: 'PUT'}
    });
    var coordinator = $resource('/api/v1/users/coordinator/:id?school=' + school_slug, {id:'@id'}, {
      update: {method: 'PUT'}
    });

    return {
      fetch_adviser_list: function() {
        return adviser.get(function(data) {
          ea_list = data.results
          return data.results
        })
      },

      fetch_coordinator_list: function() {
        return coordinator.get(function(data) {
          ec_list = data.results
          return data.results
        })
      },

      list: function(role) {
        var list = []
        if (role === 'adviser') {
          list = ea_list
        } else if (role === 'coordinator') {
          list = ec_list
        }
        return list
      },

      add_school: function(data, school) {
        data.school = {slug: school}
        return data
      },

      add_person: function(data, success_cb) {
        if (data.role === 'enterprise_adviser') {
          var r = adviser
        } else if (data.role === 'enterprise_coordinator') {
          if (typeof(data.school) === "string") {
            data = this.add_school(data, data.school)
          }
          if (!data.hasOwnProperty('school')) {
            data = this.add_school(data, school_slug);
          }
          var r = coordinator
        } else {
          return false
        }
        r.save({}, data, success_cb)
      },

      update_person: function(old, new_data, success_cb) {
        if (new_data.role === 'enterprise_adviser') {
          var r = adviser
        } else if (new_data.role === 'enterprise_coordinator') {
          if (typeof(new_data.school) === "string") {
            new_data = this.add_school(new_data, new_data.school)
          }
          if (!data.hasOwnProperty('school')) {
            data = this.add_school(data, school_slug);
          }
          var r = coordinator
        } else {
          return false
        }
        r.update({id: old.id}, new_data, success_cb)
      },

      remove_person: function(data, role, success_cb) {
        if (role === 'enterprise_adviser') {
          var r = adviser
        } else if (role === 'enterprise_coordinator') {
          if (typeof(data.school) === "string") {
            data = this.add_school(data, data.school)
          }
          var r = coordinator
        } else {
          return false
        }
        r.delete({id: data.id}, success_cb)
      },

      pick_list: function(role) {
        var list = []
        if (role === 'adviser') {
          list = this.fetch_adviser_list();
        } else if (role === 'coordinator') {
          list = this.fetch_coordinator_list();
        }
        return list
      }
    };

  }

})();
