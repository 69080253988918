(function () {
  'use strict';
  var static_url = (typeof STATIC_URL === 'undefined') ? '' : STATIC_URL;

  angular
    .module('companies', [])
    .controller('ModalCompanyIconCtrl', ModalCompanyIconCtrl)
    .controller('ModalCompanyIconInstCtrl', ModalCompanyIconInstCtrl);

  ModalCompanyIconCtrl.$inject = ['$scope', '$uibModal'];

  function ModalCompanyIconCtrl($scope, $uibModal) {
    var vm = this;

    this.$onInit = function() {
      vm.open = function (company_json) {

        $uibModal.open({
          size: 'md',
          templateUrl: static_url + 'templates/companies/company-icon-template.html',
          controller: 'ModalCompanyIconInstCtrl',
          controllerAs: 'vm',
          resolve: {
            company_json: function () {
              return company_json;
            }
          }
        });
      };
    }
  }

  ModalCompanyIconInstCtrl.$inject = [
    '$scope',
    '$uibModalInstance',
    'company_json'
  ];

  function ModalCompanyIconInstCtrl($scope, $uibModalInstance, company_json) {
    var vm = this;
    vm.company_json = company_json

    vm.close = $uibModalInstance.close;
    vm.cancel = $uibModalInstance.dismiss;

    vm.missingProfilePicture = static_url + 'images/noprofile-pic-lrg.jpg';

  }

}());
