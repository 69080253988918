/**
 * Controller for the page selecting event type, date and time.
 * Compare:
 * - the template, events/create/type-date.html (especially ng-init value)
 * - the settings, events.forms.utils.DateTimeOptionsMixin.
 *
 * TODO: End this
 *       keep-everything-in-as-many-undocumented-places-and-mixins-as-possible
 *       lunacy.
 */
(function () {
  'use strict';

  angular
    .module('events')
    .controller('EventTypeCtrl', EventTypeCtrl);

  EventTypeCtrl.$inject = ['$scope'];

  function EventTypeCtrl($scope){
    var vm = this, wvTimeOptions;
    wvTimeOptions = {
      min: [10, 0],
      max: [14, 0],
      interval: 60 * 4 // 14 - 10 = 4 hours in minutes
    };

    vm.dateOptions = {};
    vm.timeOptions = {};
    vm.eventType = null;
    vm.date = null;
    vm.time = null;
    vm.raw_time = null;
    vm.end_time = null;
    vm.eventCategory = null;
    vm.eventTypeList = null;
    vm.durationChoices = [
      {label: '1 hour', value: 60},
      {label: '30 minutes', value: 30}
    ];
    vm.selectedDuration = vm.durationChoices[0];
    vm.webinar = false;

    vm.init = function(
      defaultType, dateOptions, timeOptions, startDate, startTime, endTime,
      eventTypes, webinar
    ) {
      if (startDate && startTime && endTime) {
        var dmy, st, se;
        dmy = startDate.split('/');
        st = startTime.split(':');
        se = endTime.split(':');
        vm.date = new Date(dmy[2], Number(dmy[1]) - 1, dmy[0], st[0], st[1]);
        vm.time = new Date(vm.date);
        vm.end_time = new Date(vm.date);
        vm.end_time.setHours(se[0]);
        vm.end_time.setMinutes(se[1]);
      }
      vm.dateOptions = dateOptions;
      $scope.endTimeOptions = timeOptions;
      if (eventTypes) {
        vm.eventTypes = eventTypes;
      }
      vm.webinar = webinar;
      console.error(vm.webinar);
      vm.setEventType(defaultType);
    };

    vm.isFilled = function(){
      return vm.date !== null && vm.time !== null && vm.end_time !== null;
    };


    /** Controls if duration select field is displayed. */
    vm.needDuration = function() {
      return true;
    };

    /** Controls if manual selection of end time is displayed. */
    vm.needManualEndTime = function() {
      return false;

    };

    function getDurationMinutes() {
      return vm.selectedDuration.value;
    }

    vm.setEventTypeList = function () {
      vm.eventTypeList = vm.eventTypes;
    };

    vm.getEventTypes = function() {
      return vm.eventTypes;
    };

    vm.hasChoiceEventType = function() {
      return vm.eventTypes.length > 1 ;
    };

    vm.setEndTime = function(){
      if (!vm.time){
        return;
      }
      vm.end_time = new Date(vm.time);
      vm.end_time.setMinutes(vm.end_time.getMinutes() + getDurationMinutes());
    };

    vm.shortDescription = function(){
      if (vm.eventType) {
        return vm.eventType.description.split('.')[0].concat('.');
      }
    };

    vm.setEventType = function(desiredType){
      angular.forEach(vm.eventTypes, function(value) {
        if (value.name === desiredType) {
          vm.eventType = value;
        }
      });
      vm.selectedDuration = vm.durationChoices[0];
      $scope.timeOptions = angular.extend({}, wvTimeOptions, $scope.endTimeOptions);
      if(vm.time != null) {
        vm.setEndTime();
      }
    };

    $scope.$watch('eventCtrl.time', function(newValue, oldValue) {
      if (newValue !== oldValue) {
        vm.setEndTime();
      }
    }, true);

  }

})();
