(function () {
  'use strict';

  angular
    .module('search')
    .service('SearchService', SearchService);
  
  SearchService.$inject = ['$resource'];

  function SearchService($resource) {
    // TODO: migrate to the new API endpoints?
    var searchUrl = '/results/:uuid/api/';
    return $resource(searchUrl, {uuid: ':uuid'}, {});
  }

})();
