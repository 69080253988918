(function () {
  "use strict";

  angular
    .module('alerts')
    .controller('AlertsSubscriberController', AlertsSubscriberController);

  AlertsSubscriberController.$inject = [
    '$scope',
    'FollowerService',
    ]

  function AlertsSubscriberController($scope, FollowerService) {
      $scope.is_following = FollowerService.is_following;
      $scope.follow_toggle = function(area_id, area_type_id) {
          $scope.following = FollowerService.follow_toggle(area_id, area_type_id);
      }
      $scope.following_id = $(".btn-follow").data('following-id')
      $scope.following = FollowerService.is_following($scope.following_id);
      $scope.hoverIn = function() {
          if ($scope.following.active) {
              $scope.potential_unfollow = true;
              $scope.following.text = 'Unfollow'

          }
      }
      $scope.hoverOut = function() {
          if ($scope.following.active) {
              $scope.potential_unfollow = false;
              $scope.following.text = 'Following'
          }
      }
  }

})();
