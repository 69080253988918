(function () {
  "use strict";

  angular
    .module('insights')
    .controller('ClaimSchoolController', ClaimSchoolController);

  ClaimSchoolController.$inject = [
    '$scope',
    '$window',
    'ClaimSchoolService',
    ]

  function ClaimSchoolController($scope, $window, ClaimSchoolService) {

    $('#claimModal').on('show.bs.modal', function (event) {
      var button = $(event.relatedTarget) // Button that triggered the modal
      var type = button.data('type') // Extract info from data-* attributes
      var fname = button.data('firstname')
      var lname = button.data('lastname')
      var email = button.data('email')
      var edit = button.data('edit')
      var id = button.data('id')
      $scope.claim = {};
      // If necessary, you could initiate an AJAX request here (and then do the updating in a callback).
      // Update the modal's content. We'll use jQuery here, but you could use a data binding library or other methods instead.

      var modal = $(this);
      $scope.claim.role = type;
      $scope.claim.first_name = fname;
      $scope.claim.last_name = lname;
      $scope.claim.email = email;
      $scope.claim.id = id;
      $scope.$digest();
      if ($scope.claim.role === 'enterprise_adviser') {
        var role = 'adviser'
      } else if ($scope.claim.role === 'enterprise_coordinator') {
        var role = 'coordinator'
      }
      $scope.pick_list(role);

      $scope.edit = edit;
      if (edit === true) {
          modal.find('.modal-footer #delete').removeClass('hidden')
          modal.find('.modal-footer #create').text('Update')
      } else {
          modal.find('.modal-footer #delete').addClass('hidden')
          modal.find('.modal-footer #create').addClass('Submit')
      }

    }); // end of event

    var success_callback = function(resp) {
      if ($scope.claim.role === 'enterprise_adviser') {
        var role = 'adviser'
      } else if ($scope.claim.role === 'enterprise_coordinator') {
        var role = 'coordinator'
      }
      $scope.pick_list(role);
      // close modal and open thanks one
      // need to hide the button and display name of Adviser
      $('#claimModal').modal('hide');

    }

    $scope.claim_submit = function() {
      if ($scope.edit) {
        var data = $scope.list.results.filter(function(value) {
          return $scope.claim.id === value.id
        }).shift();
        ClaimSchoolService.update_person(data, $scope.claim, function(data) {
          success_callback(data);
          $('#updateModal').modal('show');
        })
      } else {
        ClaimSchoolService.add_person($scope.claim, function(data) {
          success_callback(data);
          $('#thanksModal').modal('show');
        })
      }
    }

    $scope.delete_claim = function() {
      var data = $scope.list.results.filter(function(value) {
        return $scope.claim.id === value.id
      }).shift();
      ClaimSchoolService.remove_person(data, $scope.claim.role, function(data) {
        success_callback(data);
        $('#deleteModal').modal('show');
      })
    }

    $scope.pick_list = function(role) {
      $scope.list = ClaimSchoolService.pick_list(role)
    }
}

})();
