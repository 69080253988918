(function () {
  'use strict';

  angular
    .module('alerts')
    .controller('AlertsSubscriberFormController', AlertsSubscriberFormController);

  AlertsSubscriberFormController.$inject = [
    '$scope',
    '$cookies',
    'FollowerService',
  ];

  function AlertsSubscriberFormController($scope, $cookies, FollowerService) {
    $scope.follow_toggle = function (area_id, area_type_id) {
      $scope.following = FollowerService.follow_toggle(area_id, area_type_id, function (alert) {
        alert.follower = {};
        alert.follower.organization = $scope.organization;
        alert.follower.job_title = $scope.job_title;
        alert.follower.user = $scope.user;
        alert.follower.profession = $scope.profession;
        alert.follower.profession_other = $scope.profession_other;
        return alert;
      });
      $cookies.putObject('alertUser', $scope.user);
      $cookies.putObject('alertOrg', $scope.organization);
      $cookies.putObject('alertJob', $scope.job_title);
      $cookies.putObject('alertProfession', $scope.profession);
      $cookies.putObject('alertProfessionOther', $scope.profession_other);
      //   close modal
      $scope.following.text = 'Following';
      $('#alertsModal').modal('hide');
      $('#confirmModal').modal('show');
    };
    $scope.following = FollowerService.is_following($scope.following_id);
    var getFollowerFromCookies = function () {
      $scope.user = $cookies.getObject('alertUser');
      $scope.organization = $cookies.getObject('alertOrg');
      $scope.job_title = $cookies.getObject('alertJob');
      $scope.profession = $cookies.getObject('alertProfession');
      $scope.profession_other = $cookies.getObject('alertProfessionOther');
    };
    getFollowerFromCookies();

    $scope.got_all_fields = function () {
      // ensures that all fields are complete before submission
      // If the user selects 'other' as their profession, the 'profession_other'
      // field is mandatory.
      var user_keys = ['email', 'first_name', 'last_name'];
      if ($scope.user !== undefined) {
        return user_keys.some(function (value) {
          return !$scope.user.hasOwnProperty(value) || $scope.user[value] === undefined;
        }) || !($scope.job_title && $scope.organization && $scope.profession && ($scope.profession!=='Other' || $scope.profession_other));
      } else {
        return true;
      }
    };
  }

})();
