(function () {
  'use strict';

  angular
    .module('charts')
    .directive('flatPieChart', flatPieChart);

  flatPieChart.$inject = ['$resource', 'HighchartsSvc', 'OptionsBuilder'];

  function flatPieChart($resource, HighchartsSvc, OptionsBuilder) {
    var loaderTemplate = '<div class="three-dots-loader"></div>';
    return {
      restrict: 'E',
      template: loaderTemplate,
      scope: {
        title: '@chartTitle',
        resource: '=?chartResource',
        url: '=?chartUrl',
        legend: '=chartLegend'
      },
      /**
       * Watch for scope.resource, fetch data when changes
       * to any non-emtpy value and build the chart
       */
      link: function (scope, element) {

        var chart;

        if(!scope.resource) {
          scope.resource = $resource(
            scope.url,
            {},
            {fetchPieData: {method: 'GET', isArray: true, cache: true}}
          )
        }

        function fetchData() {
          if(chart) {
            // Destroy the chart and replace loaders
            chart.destroy();
            element.append(loaderTemplate);
          }
          var dataPromise = scope.resource.fetchPieData();
          dataPromise.$promise.then(function (data) {
            var options = OptionsBuilder.flatPie(scope.title, data, scope.legend);
            chart = HighchartsSvc.chart(element[0], options);
          });
        }

        scope.$watch('resource', fetchData);

        scope.$on('$destroy', function () {
          if (chart) {
            chart.destroy();
          }
        });

      }
    };
  }

})();
