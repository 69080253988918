(function () {
  'use strict';
  var static_url = (typeof STATIC_URL === 'undefined') ? '' : STATIC_URL;

  angular
    .module('search')
    .directive('resultsListing', resultsListing);

  function resultsListing() {    
    return {
      restrict: 'E',
      templateUrl: function(elem, attr) {
        return static_url + 'templates/search/results-' + attr.type + '.template.html';
      },
      scope: {
        resultSpeaker: '=',
        toggleSpeaker: '&',
        removeSpeaker: '&',
        addSpeaker: '&',
        showSpeakerInfo: '&',
        shortlistedClass: '&',
        isShortlisted: '&'
      },
      link: link
    };

    function link (scope, element, attrs) {
      if(typeof scope.missingPicture === 'undefined') {
        scope.missingPicture = static_url + 'images/noprofile-pic-lrg.jpg';
      }
      scope.linkedinImg = static_url + 'images/reskin/linkedin.png';
      scope.duedilImg = static_url + 'images/reskin/duedil.png';
    }

  }

})();
