(function(){
  "use strict";

  angular
    .module('common.slide', [])
    .controller('SlideController', SlideController);

  SlideController.$inject = ['$scope'];

  function SlideController($scope){
    var vm = this;
    vm.isHidden = true;
    vm.name = null;

    vm.init = function(name, hidden){
      vm.name = name;
      vm.isHidden = (typeof hidden === 'boolean') ? hidden : true;
    };

    vm.toggle = function(){
      vm.isHidden = !vm.isHidden;
    };

    $scope.$on('f4s.common.slideClose', function(event, name){
      if(vm.name !== null && vm.name === name){
        vm.isHidden = true;
      }
    });
  };
})();
