(function () {
  'use strict';

  angular
    .module('charts')
    .service('OptionsBuilder', OptionsBuilder);

  function OptionsBuilder() {
    var vm = this;

    /**
     * Build general options
     */
    var baseOptions = {
      credits: {
        text: 'Copyright Founders4Schools',
        href: 'https://www.founders4schools.org.uk'
      },
      exporting: {
        enabled: true
      },
      tooltip: {
        pointFormat: '<b>{point.y}</b>'
      }
    };

    /**
     * Pie chart options
     */
    var pieChartOptions = angular.extend({}, baseOptions, {
      chart: {
        type: 'pie',
        borderColor: '#CCC',
        borderWidth: 1
      },
      title: {
        text: '',
        align: 'left'
      },
      tooltip: {
        pointFormat: '<b>{point.y}</b>'
      },
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          showInLegend: true,
          dataLabels: {
            enabled: true,
            formatter: function () {
              if (this.percentage < 5) return '';
              return Math.round(this.percentage * 10) / 10 + ' %';
            },
            distance: -20,
            color: 'white'
          }
        }
      },
      legend: {
        align: 'right',
        verticalAlign: 'top',
        layout: 'vertical'
      },
      series: [{
        name: '',
        colorByPoint: true,
        data: []
      }]
    });

    /**
     * Columns charts options
     */
    var stackedColumnOptions = angular.extend({}, baseOptions, {
      chart: {
        type: 'column',
        borderColor: '#CCC',
        borderWidth: 1
      },
      title: {
        text: ''
      },
      xAxis: {
        categories: []
      },
      yAxis: {
        min: 0,
        title: {
          text: ''
        },
        stackLabels: {
          enabled: true,
          formatter: function() {
            return Math.round(this.total);
          },
          style: {
            fontWeight: 'bold'
          }
        }
      },
      legend: {
        align: 'left',
        verticalAlign: 'middle',
        layout: 'vertical',
        borderColor: '#CCC',
        borderWidth: 1
      },
      tooltip: {
        headerFormat: '<b>{point.x}</b><br/>',
        pointFormatter: function () {
          return Math.round(this.y);
        }
      },
      plotOptions: {
        column: {
          stacking: 'normal',
          dataLabels: {
            enabled: false,
            style: {
              textShadow: '0 0 3px black'
            }
          }
        }
      },
      series: []
    });

    /**
     * Services method below
     */
      vm.flatPie = function (title, data, legend) {
        var options = angular.extend({}, pieChartOptions);
        options.title.text = title;
        options.series[0].name = title;
        options.series[0].data = data;
        if (legend === false) {
          options.plotOptions.pie.showInLegend = false;
        }
        return options;
      };

      vm.columnChart = function (title, yTitle, data) {
        var options = angular.extend({}, stackedColumnOptions);
        options.title.text = title;
        options.xAxis.categories = data.categories;
        options.yAxis.title = yTitle;
        options.series = data.series;
        return options;
      };
  }
})();
