(function () {
  'use strict';

  angular
    .module('events')
    .controller('ModalPreviewEventCtrl', ModalPreviewEventCtrl)
    .controller('ModalPreviewEventInstanceCtrl', ModalPreviewEventInstanceCtrl)
    .directive('iframeSetHeightOnload', iframeSetHeightOnload);

  ModalPreviewEventCtrl.$inject = ['$uibModal'];

  function ModalPreviewEventCtrl($uibModal){
    var vm = this;
    var static_url = (typeof STATIC_URL === 'undefined') ? '' : STATIC_URL;

    vm.open = function (previewEventUrl) {

      $uibModal.open({
        size: 'preview-event',
        templateUrl: static_url + 'templates/events/previewEventModal.html',
        controller: 'ModalPreviewEventInstanceCtrl',
        controllerAs: 'vm',
        resolve: {
          eventUrl: function() { return previewEventUrl; }
        }
      });
    };

  }

  // Modal instance
  ModalPreviewEventInstanceCtrl.$inject = ['$uibModalInstance', 'eventUrl'];

  function ModalPreviewEventInstanceCtrl($uibModalInstance, eventUrl) {
    var vm = this;
    vm.eventUrl = eventUrl;
    vm.confirm = $uibModalInstance.close;
    vm.cancel = $uibModalInstance.dismiss;
  }

  // To set the height of the iframe onload()

  function iframeSetHeightOnload() {
    return {
      restrict: 'A',
      link: linkFunction
    };

    function linkFunction(scope, element) {
      element.on('load', function () {
        var iFrameHeight = element[0].contentWindow.document.body.scrollHeight + 'px';
        element.css('height', iFrameHeight);
      });
    }
  }

})();
