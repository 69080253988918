(function () {
  'use strict';

  angular
    .module('charts')
    .directive('columnChart', columnChart);

  columnChart.$inject = ['HighchartsSvc', 'OptionsBuilder'];

  function columnChart(HighchartsSvc, OptionsBuilder) {
    var loaderTemplate = '<div class="three-dots-loader"></div>';

    return {
      restrict: 'E',
      template: loaderTemplate,
      scope: {
        title: '@chartTitle',
        yTitle: '@chartYTitle',
        resource: '=chartResource',
        extraParams: '=chartExtraParams',
        legend: '=chartLegend'
      },
      /**
       * Watch for scope.url, fetch data when changes
       * to any non-emtpy value and build the chart
       */
      link: function (scope, element) {

        var chart;

        function fetchData(url) {
          if (url) {
            if(chart) {
              // Destroy the chart and replace loaders
              chart.destroy();
              element.append(loaderTemplate);
            }
            var dataPromise = scope.resource.fetchColumnsData(scope.extraParams);
            dataPromise.$promise.then(function (data) {
              var options = OptionsBuilder.columnChart(scope.title, scope.yTitle, data);
              chart = HighchartsSvc.chart(element[0], options);
            });
          }
        }

        scope.$watch('extraParams', fetchData);

        scope.$on('$destroy', function () {
          if (chart) {
            chart.destroy();
          }
        });

      }
    };
  }

})();
