(function () {
  'use strict';
  var static_url = (typeof STATIC_URL === 'undefined') ? '' : STATIC_URL;

  angular
    .module('search')
    .directive('facetCheckbox', facetCheckbox);

  function facetCheckbox() {
    return {
      restrict: 'E',
      templateUrl: function() {
        return static_url + 'templates/search/facet-checkbox.template.html';
      },
      scope: {
        filter: '=facetFilter',
        item: '=facetItem',
        resultCtrl: '='
      }
    };

  }

})();
