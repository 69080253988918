(function () {
  "use strict";

  angular
    .module('invites')
    .service('AddressService', AddressService)
    .service('BLCService', BLCService);

  /* Service to update the invite response */
  AddressService.$inject = ['$resource'];
  BLCService.$inject = ['$resource'];

  function AddressService($resource){
    return $resource('/api/v1/geo/address/:id', {}, {})
  };

  function BLCService($resource) {
    return $resource('/api/v1/leaders/businessleadercompany/:id', {},{
      update: {method: 'PUT'}
    })
  }

})();
