(function () {
  "use strict";

  angular
    .module('insights')
    .controller('ReportIssueController', ReportIssueController);

  ReportIssueController.$inject = [
    '$scope',
    '$resource',
    '$window'
  ];

  function ReportIssueController($scope, $resource, $window) {
    // Magic dependency on the url structure /insights/schools/entity_slug/school_slug
    $scope.entity_slug = $window.location.pathname.split('/')[3];
    $scope.school_slug = $window.location.pathname.split('/')[4];
    $scope.report = function() {
      $scope.issue.school = $scope.school_slug;

      // Custom resource to report an issue
      var IssueReporter = $resource(
        /// URL
        '/insights/schools/:area/:school/issue',
        // Default parameters
        {area: $scope.entity_slug, school: $scope.school_slug},
        // Custom action
        {report: {method: 'POST'}}
      );

      // Arguments are: (parameters, request body)
      IssueReporter.report({}, $scope.issue).$promise.then(
        function(resp) {
          // close modal and open thanks one
          $('#thanksIssueModal').modal('show');
          // need to hide the button and display name of Adviser
          $('#issueModal').modal('hide');
        }
      );
    }
  }

})();
