(function () {
  'use strict';

  angular
    .module('search')
    .factory('rangeFilter', rangeFilter);

  rangeFilter.$inject = ['$location', 'Analytics'];

  function rangeFilter($location, Analytics) {
    return function (filterObj) {
      var vm = {
        filterObj: filterObj,
        category: filterObj.slug,
        title: filterObj.title,
        model: {},
        // All options
        validMinOptions: filterObj.options,
        // All except the first one, replaced by a special value
        validMaxOptions: [{name: 'No max', slug: ''}].concat(filterObj.options.slice(1)),
        type: 'range'
      };

      // Sensible defaults
      vm.model.min = vm.validMinOptions[0];
      vm.model.max = vm.validMaxOptions[0];

      // Retrieve defaults if URL parameter is filled
      var value = $location.search()[vm.category];
      if(value) {
        var split_vals = value.split('-');
        if (split_vals.length > 0) {
          angular.forEach(vm.validMinOptions, function (option) {
            if (option.slug === split_vals[0]) {
              vm.model.min = option;
            }
          });
        }
        if (split_vals.length > 1) {
          angular.forEach(vm.validMaxOptions, function (option) {
            if (option.slug === split_vals[1]) {
              vm.model.max = option;
            }
          });
        }
      }

      /**
       * Build the GET params value for this specific filter:
       * Value is a string with min and max separated by '-'
       * - Min has to be set
       * - If no '-' in the string --> no max set
       */
      vm.getParams = function () {
        var params_list = [];
        vm.model.min.slug ? params_list.push(vm.model.min.slug) : params_list;
        vm.model.max.slug ? params_list.push(vm.model.max.slug) : params_list;
        var param = params_list.join(vm.filterObj.separator);
        return param;
      };

      /**
       * Update display of filter based on aggregation returned in the results
       * Not used in this context, declared just to keep the API consistent
       */
      vm.updateFilter = function () {};

      /**
       * Called just after the model has been set, before running the Ajax call
       * Update choices in the filter before loading the results to make sure
       * the selection is consistent
       */
      vm.onSet = function(item) {
        if(item === 'min') {
          Analytics.trackEvent('SearchFilter', 'change lower bound', vm.model.min.name);
        } else {
          Analytics.trackEvent('SearchFilter', 'change upper bound', vm.model.max.name);
        }
        var minIdx = vm.validMinOptions.indexOf(vm.model.min);
        var maxIdx = vm.validMaxOptions.indexOf(vm.model.max);
        if (maxIdx === 0 || maxIdx > minIdx) {
          // no max set OR max bigger than min, nothing to do
          return;
        }
        // Inconsistency: reset default choice
        if (item === 'min') {
          vm.model.max = vm.validMaxOptions[0];
          return;
        }
        if (item === 'max') {
          vm.model.min = vm.validMinOptions[0];
          return;
        }
      };

      // Return the filter so it can be used outside
      return vm;
    };
  }

}) ();
