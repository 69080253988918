(function () {
  "use strict";

  angular
    .module('alerts.services', ['ngResource'])
    .service('AlertService', AlertResourceService)
    .service('FollowerService', FollowerService);



  FollowerService.$inject = [
      "AlertService"
  ]

  function FollowerService(AlertService) {
      var following = {}
      var is_following = function(following_id) {
          if (following_id > 0) {
              following = AlertService.get({id:following_id}, function(data) {
                  following = data;
                  if (following.active) {
                      following.text = 'Following'
                  } else {
                      following.text = 'Follow'
                  }
                  return following;
              })
          } else {
            //   var following = {};
              following.active = false;
              following.text = 'Follow'
          }

          return following
      }
      var follow_toggle = function(area_id, area_type_id, data_func_adder) {
          if (following.active === false) {
              if (following.hasOwnProperty('id') && following.id > 0) {
                  var data = following
                  data.active = true;
                  AlertService.update({id:data.id}, data, function(resp) {
                      following = data;
                      following.text = 'Following'
                  })
              } else {
                  var data = {
                      following_type: area_type_id,
                      object_id: area_id
                  }
                  if (data_func_adder !== undefined) {
                      data = data_func_adder(data);
                  }
                  following.active = true;
                  AlertService.save({}, data, function(resp) {
                      following = data;
                      following.text = 'Following'

                  })
              }
          } else {
              var data = following
              data.active = false;
              console.log(data)
              AlertService.update({id:data.id}, data, function(resp) {
                  following = data;
                  following.text = 'Follow'
              })
          }
          return following
      }
      return {
          follow_toggle: follow_toggle,
          is_following: is_following,
      }
  }

    AlertResourceService.$inject = [
        '$resource',
    ];

    function AlertResourceService($resource) {
        var csrf = $.cookie('csrftoken');
        var resource = $resource('/alerts/api/:id/', {format: 'json'}, {
            'save': {method:'POST', headers: {'X-CSRFToken': csrf}},
            'update': {method:'PUT', headers: {'X-CSRFToken': csrf}},
        }, {stripTrailingSlashes: false});
        return resource;
    }

})();
