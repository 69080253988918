(function () {
  'use strict';
  var static_url = (typeof STATIC_URL === 'undefined') ? '' : STATIC_URL;

  angular
    .module('leaders.profile', ['common.errsrc', 'common.filters'])
    .controller('ModalLeaderProfileCtrl', ModalLeaderProfileCtrl)
    .controller('ModalLeaderProfileInstCtrl', ModalLeaderProfileInstCtrl);

  ModalLeaderProfileCtrl.$inject = ['$scope', '$uibModal'];

  function ModalLeaderProfileCtrl($scope, $uibModal) {
    var vm = this;

    this.$onInit = function () {
      vm.open = function (speaker) {

        $uibModal.open({
          size: 'xlg',
          templateUrl: static_url + 'templates/leaders/leaderProfileModal.html',
          controller: 'ModalLeaderProfileInstCtrl',
          controllerAs: 'vm',
          resolve: {
            speaker: function () {
              return speaker;
            },
            isShortlisted: function () {
              return $scope.isShortlisted;
            },
            addSpeaker: function () {
              return $scope.addSpeaker;
            },
            removeSpeaker: function () {
              return $scope.removeSpeaker;
            }
          }
        });
      };
    }
  }

  ModalLeaderProfileInstCtrl.$inject = [
    '$scope',
    '$uibModalInstance',
    'speaker',
    'isShortlisted',
    'addSpeaker',
    'removeSpeaker'
  ];

  function ModalLeaderProfileInstCtrl($scope, $uibModalInstance, speaker, isShortlisted, addSpeaker, removeSpeaker) {
    var vm = this;
    vm.speaker = speaker;
    vm.close = $uibModalInstance.close;
    vm.cancel = $uibModalInstance.dismiss;

    $scope.isShortlisted = isShortlisted;
    $scope.addSpeaker = addSpeaker;
    $scope.removeSpeaker = removeSpeaker;

    vm.missingProfilePicture = static_url + 'images/noprofile-pic-lrg.jpg';

    vm.hasValidIcon = function(){
      if(!vm.speaker || !vm.speaker.tags || vm.speaker.tags.length === 0){
        return false;
      }
      for(var i in vm.speaker.tags){
        if(vm.speaker.tags[i].goal === true){
          return true;
        }
      }
      return false;
    };

    vm.hasBeenReferred = function(){
      if (vm.speaker && vm.speaker.is_referred === 1){
        return true;
      }
      return false;
    };
  }

})();
