(function () {
  "use strict";

  angular
    .module('alerts')
    .directive('alertSub', alertSub);

  function alertSub() {
      return {
          restrict: 'A',
          templateUrl: STATIC_URL + 'templates/alertSub/template.html',
      }
  }

})();
