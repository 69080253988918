(function () {
  "use strict";


  angular
    .module('notifications.alerts', [
        'alerts.services'
    ])
    .controller('AlertManagementCtrl', AlertManagementCtrl);

  AlertManagementCtrl.$inject = [
    '$scope',
    '$http',
    '$cookies',
    'AlertService',
    ]

  function AlertManagementCtrl($scope, $http, $cookies, AlertService) {
      $scope.toggle = function(id) {
          AlertService.get({id:id}, function(data) {
              data.active = $scope.state[id]
              AlertService.update({id:id}, data, function(data) {
                  console.log(data)
              })
          })

      }
      $scope.unsub_alerts = function() {
          $http.post('/alerts/api/all/unsub/', {}, {
              headers: {'X-CSRFToken': $cookies.get('csrftoken')},
              params: {'uuid': $scope.uuid}
          }).then(
              function (resp) {
                  angular.forEach($scope.state, function(value, key){
                      this[key] = false
                  }, $scope.state)
              }
          )
      }
  }

})();
