(function () {
  'use strict';
  var static_url = (typeof STATIC_URL === 'undefined') ? '' : STATIC_URL;

  angular
    .module('search')
    .controller('SearchFormCtrl', SearchFormCtrl)
    .directive('searchForm', searchForm);

  function searchForm() {
    return {
      restrict: 'E',
      templateUrl: function() {
        return static_url + 'templates/search/search-form.template.html';
      },
      scope: {
        buttonLabel: '@?',
        formClass: '@?',
        dropDownClass: '@?',
        postcodeClass: '@?',
        buttonClass: '@?',
        eventTypeOptions: '=',
        csrfTokenValue: '@',
        searchReferrer: '@?'
      },
      controller: 'SearchFormCtrl',
      controllerAs: 'vm',
      bindToController: true
    };

  }

  SearchFormCtrl.$inject = [];

  /**
   *
   * Attempts to pull the csrf_token out of the cookie.  This probably
   * shouldn't live here, but I'm unsure as to where it should be just yet.
   *
   */
  function getCookie(name) {
      var cookieValue = null;
      if (document.cookie && document.cookie !== '') {
          var cookies = document.cookie.split(';');
          for (var i = 0; i < cookies.length; i++) {
              var cookie = jQuery.trim(cookies[i]);
              // Does this cookie string begin with the name we want?
              if (cookie.substring(0, name.length + 1) === (name + '=')) {
                  cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                  break;
              }
          }
      }
      return cookieValue;
  }

  function SearchFormCtrl() {
    var vm = this;

    this.$onInit = function () {
      vm.postcode = '';

      if (!vm.csrfTokenValue) {
        vm.csrfTokenValue = getCookie('csrftoken');
      }

      vm.searchUrl = '/search/full/';
      vm.buttonLabel = angular.isDefined(vm.buttonLabel) ? vm.buttonLabel : 'Search';
      vm.eventType = vm.eventTypeOptions[0].value;
      vm.formClass = angular.isDefined(vm.formClass) ? vm.formClass : 'form-horizontal';
      if (!angular.isDefined(vm.dropDownClass)) {
        vm.dropDownClass = vm.eventTypeOptions.length > 1 ? 'col-sm-3' : 'hidden';
      }
    }
  }

})();
