(function() {
  'use strict';

  angular
    .module('common.buttons.spinner', [])
    .directive('spinnerButton', spinnerButton);

  spinnerButton.$inject = [];

  function spinnerButton() {
    var static_url = (typeof STATIC_URL === 'undefined') ? '' : STATIC_URL;
    return {
      restrict: 'E',
      transclude: true,
      templateUrl: static_url + 'templates/common/spinner-button.template.html',
      scope: {
        isDisabled: '&isDisabled',
        click: '&onClick',
        showSpinner: '&',
        btnClass: '@'
      },
      link: link
    };

    function link (scope, element) {
      if(typeof scope.btnClass === 'undefined') {
        scope.btnClass = 'btn btn-primary';
      }
    }
  }

})();
