(function() {
  'use strict';

  angular
    .module('search.gridlist', [])
    .controller('GridListCtrl', GridListCtrl);

  function GridListCtrl() {
    var vm = this,
      list = 'list',
      grid = 'grid';

    this.$onInit = function () {
      vm.currentDisplay = grid;

      vm.showList = function () {
        setDisplay(list);
      };

      vm.isList = function () {
        return isDisplay(list);
      };

      vm.showGrid = function () {
        setDisplay(grid);
      };

      vm.isGrid = function () {
        return isDisplay(grid);
      };

      function setDisplay(ref) {
        vm.currentDisplay = ref;
      }

      function isDisplay(ref) {
        return vm.currentDisplay === ref;
      }
    }
  }
})();
