(function () {
  'use strict';
  var static_url = (typeof STATIC_URL === 'undefined') ? '' : STATIC_URL;

  angular
    .module('search')
    .directive('searchFilter', searchFilter);

  function searchFilter() {
    return {
      restrict: 'E',
      transclude: true,
      templateUrl: function() {
        return static_url + 'templates/search/search-filter.template.html';
      },
      scope: {
        filter: '=filterObj'
      }
    };

  }

})();
