(function () {
  'use strict';

  angular
    .module('common.sharing')
    .controller('ShareController', ShareController);
  
  function ShareController(){
    var vm = this;
    vm.showBtnGroup = false;
    vm.btnCls = (typeof vm.btnSizeMod === 'undefined') ? '' : 'btn-' + vm.btnSizeMod;
    vm.btnGroupCls = (typeof vm.btnSizeMod === 'undefined') ? '' : 'btn-group-' + vm.btnSizeMod;
  }

})();
