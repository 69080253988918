(function () {
  'use strict';

  angular
    .module('invites')
    .service('InviteService', InviteService);

  /* Service to update the invite response */
  InviteService.$inject = ['$resource'];

  function InviteService($resource){
    return $resource('/invites/api/:uuid', {}, {
      decline: {method: 'PUT', params: {uuid: '@uuid'}, url: '/invites/api/:uuid/decline/'},
      sendFeedback: {method: 'PUT', params: {uuid: '@uuid'}, url: '/invites/api/:uuid/feedback/'}
    });
  }

})();
