(function () {
  'use strict';
  var static_url = (typeof STATIC_URL === 'undefined') ? '' : STATIC_URL;

  angular
    .module('partners')
    .directive('partnerCard', partnerCard);

  function partnerCard() {
    return {
      templateUrl: function() {
        return static_url + 'templates/partners/partner-card-template.html';
      }
    };

  }

}());
