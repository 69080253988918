/**
 * Custom fork due with a few tweaks:
 * - Do not set the z-index in javascript
 * - Clean-up commented code
 * - Reverse a few default false -> true
 * - Formatting to be more readable
 *
 * Angular JS aside menu directive
 *
 * Original version:
 * (c) Mahmut Duva <mahmutduva@gmail.com>
 * https://github.com/mahmutduva/angular-aside-menu
 */


(function() {
  'use strict';
  angular.module('asideModule', [])
    .directive('asideMenuToggle', asideMenuToggle)
    .directive('asideMenu', asideMenu)
    .directive('asideMenuContent', asideMenuContent)
    .directive('closeAsideMenu', closeAsideMenu);

  asideMenuToggle.$inject = ['$compile'];

  function asideMenuToggle($compile) {
    'use strict';

    function buildOverrides(elemItem) {
      var overrides = {};
      var rawOverrides = {
        width: elemItem.attr('width'),
        side: elemItem.attr('side'),
        pushContent: elemItem.attr('push-content'),
        isBackdrop: elemItem.attr('is-backdrop'),
        squeeze: elemItem.attr('squeeze')
      };
      angular.forEach(rawOverrides, function (overrideValue, overrideKey) {
        if (angular.isDefined(overrideValue)) {
          overrides[overrideKey] = overrideValue;
        }
      });
      return overrides;
    }

    return {
      restrict: 'EA',
      link: function (scope, elem, attrs) {
        // is open menu
        scope.isOpen = false;
        // All Menus
        scope.menus = document.getElementsByTagName('aside-menu');
        // Menu Content
        scope.menuContent = document.getElementsByClassName('aside-menu-content');
        // Default menu Options
        scope.targetMenu = {
          item: null,
          pushContent: false,
          isBackdrop: true,
          squeeze: false
        };

        // Click Event
        elem.bind('click', function () {
          // reset menu content transform
          angular.element(scope.menuContent).css('transform', 'translate3d(0, 0, 0)');
          // List all menus
          angular.forEach(scope.menus, function (item) {
            // Close menu
            if (!angular.element(item).attr('fixed')) {
              angular.element(item).css('transform', 'translate3d(0, 0, 0)');
            }
            // Get target menu options from attrs
            if (angular.element(item).attr('id') == attrs.asideMenuToggle) {
              // Overrides default with specified attributes
              var elemItem = angular.element(item);
              scope.targetMenu.item = elemItem;
              angular.extend(scope.targetMenu, buildOverrides(elemItem));

              if (angular.element(item).attr('open')) {
                angular.element(item).attr('open', false);
              } else {
                angular.element(item).attr('open', true);
              }
              scope.targetMenu.open = angular.element(item).attr('open');
            }
          });

          if (scope.targetMenu.open) {
            if (scope.targetMenu.side == 'left') {
              if (scope.targetMenu.pushContent == 'true') {
                angular.element(scope.menuContent).css('transform', 'translate3d(' + scope.targetMenu.width + ', 0, 0)');
              }
              else {
                scope.targetMenu.item.css('transform', 'translate3d(' + scope.targetMenu.width + ', 0, 0)');
              }

              if (scope.targetMenu.squeeze == 'true') {
                angular.element(scope.menuContent).animate({width: '-=' + scope.targetMenu.width}, 300);
              }
            }
            else if (scope.targetMenu.side == 'right') {
              if (scope.targetMenu.pushContent == 'true') {
                angular.element(scope.menuContent).css('transform', 'translate3d(-' + scope.targetMenu.width + ', 0, 0)');
              }
              if (scope.targetMenu.squeeze == 'true') {
                angular.element(scope.menuContent).animate({width: '-=' + scope.targetMenu.width}, 300);
              }
            }
            if (scope.targetMenu.isBackdrop == 'true') {
              var el = $compile('<div close-aside-menu class="aside-back-drop in"></div>')(scope);
              angular.element(scope.menuContent).append(el);
            }
          }
          else {
            if (scope.targetMenu.pushContent == 'true') {
              angular.element(scope.menuContent).css('transform', 'translate3d(0, 0, 0)');
            }
            else {
              scope.targetMenu.item.css('transform', 'translate3d(0, 0, 0)');
            }

            if (scope.targetMenu.squeeze == 'true') {
              angular.element(scope.menuContent).animate({width: '+=' + scope.targetMenu.width}, 300);
            }
          }

        });
      }
    };
  }


  function asideMenu() {
    'use strict';

    return {
      restrict: 'EA',
      link: function (scope, elem, attrs) {

        angular.element(elem).addClass('aside-menu aside-menu-animate');
        angular.element(elem).css({'width': attrs.width});

        if (attrs.side == 'left') {
          angular.element(elem).css('left', 0);
          if (attrs.pushContent == 'false') {
            angular.element(elem).css('left', '-' + attrs.width);
          }
        }
        else if (attrs.side == 'right') {
          angular.element(elem).css('right', 0);
          if (attrs.pushContent == 'false') {
            angular.element(elem).css('right', '-' + attrs.width);
          }
        }
      }
    };
  }


  function asideMenuContent() {
    'use strict';

    return {
      restrict: 'AE',
      scope: true,
      link: function (scope, elem) {
        angular.element(elem).addClass('aside-menu-content aside-menu-animate close-aside-menu');
      }
    };
  }


  function closeAsideMenu() {
    'use strict';

    return {
      restrict: 'AC',
      link: function (scope, elem) {
        elem.bind('click', function () {
          var menuContent = document.getElementsByClassName('aside-menu-content');
          angular.element(menuContent).css('transform', 'translate3d(0, 0, 0)');
          var menus = document.getElementsByTagName('aside-menu');
          angular.forEach(menus, function (item) {
            angular.element(item).css('transform', 'translate3d(0, 0, 0)');
            angular.element(item).attr('open', false);
          });
          var asideBackdrop = document.getElementsByClassName('aside-back-drop');
          angular.element(asideBackdrop).remove();
        });
      }
    };
  }

})();
