(function() {
  'use strict';

  angular
    .module('donate', ['720kb.socialshare'])
    .controller('DonateCtrl', DonateCtrl);

  function DonateCtrl() {
    var ctrl = this;

    ctrl.amount = 50;
    ctrl.frequencies = [
      {id: 2, name: "Monthly"},
      {id: 1, name: "Single"}
    ];
    ctrl.frequency = ctrl.frequencies[0];

    ctrl.isMonthly = function(){
      return ctrl.frequency.name === ctrl.frequencies[0].name;
    };

    ctrl.isSingle = function(){
      return ctrl.frequency.name === ctrl.frequencies[1].name;
    };

    ctrl.init = function(monthlyId, singleId){
      ctrl.frequencies[0].id = monthlyId;
      ctrl.frequencies[1].id = singleId;
    }
  }
})();
