(function () {
  'use strict';

  angular
    .module('events')
    .controller('EventDetailsCtrl', EventDetailsCtrl);

  EventDetailsCtrl.$inject = ['$scope'];

  function EventDetailsCtrl($scope) {
    var vm = this;

    vm.webinar = false;

    this.$onInit = function() {
      vm.other_id = null;

      vm.isFormFilled = function (regFormName) {
        var event_form_filled = (
          $scope.class_size !== "0" && // we pass down "0" as a default value and letting this through causes a 500
          $scope.title &&
          $scope.description
        );
        if (regFormName === '') {
          return event_form_filled;
        }
        var register_form_filled = (
          $scope.first_name &&
          $scope.last_name &&
          $scope.password &&
          $scope.email &&
          $scope.referrer &&
          $scope.agreed_tc
        );
        return event_form_filled && register_form_filled;
      };

      vm.showOther = function () {
        return $scope.referrer === vm.other_id;
      };

      vm.init = function (
        other_id,
        title_max_length,
        default_title,
        default_description,
        default_school,
        default_class_size,
        default_ages,
        webinar
      ) {
        vm.other_id = other_id;
        vm.title_max_length = title_max_length;
        $scope.title = default_title;
        $scope.description = default_description;
        $scope.school = default_school;
        $scope.class_size = default_class_size;
        $scope.webinar = webinar;
      };

      vm.charactersLeft = function () {
        return vm.title_max_length - $scope.title.length;
      };

      vm.titleTooLong = function () {
        return vm.charactersLeft() < 0;
      };
    }
  }

})();
