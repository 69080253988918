(function () {
  'use strict';

  angular
    .module('events')
    .controller('ModalLoginRegisterCtrl', ModalLoginRegisterCtrl);

  ModalLoginRegisterCtrl.$inject = ['$uibModal'];

  function ModalLoginRegisterCtrl($uibModal){
    var vm = this;

    this.$onInit = function() {
      vm.open = function (username) {
        $uibModal.open({
          size: 'lg',
          templateUrl: 'loginOrRegister.html',
          controller: 'ModalLoginInstanceCtrl',
          controllerAs: 'modalLoginInstCtrl',
          resolve: {
            username: function () {
              return username;
            }
          }
        });
      };
    }

  }

  angular
    .module('events')
    .controller('ModalLoginInstanceCtrl', ModalLoginInstanceCtrl);

  ModalLoginInstanceCtrl.$inject = ['$uibModalInstance', '$http', '$window', 'username'];

  function ModalLoginInstanceCtrl($uibModalInstance, $http, $window, username) {
    var vm = this;
    vm.data = {
      username: username,
      password: ''
    };
    vm.formErrors = false;
    vm.working = false;

    vm.returning = username !== '';

    vm.login = function () {
      vm.formErrors = false;
      vm.working = true;
      $http.post('/ajaxlogin/', vm.data)
        .then(loginSuccess, loginFailed)
        .then(function(){vm.working=false;});
    };

    function loginSuccess(){
      $window.location.reload();
      $uibModalInstance.close();
    }

    function loginFailed(error){
      vm.formErrors = error.data.error;
    }

    vm.cancel = function () {
      $uibModalInstance.dismiss('cancel');
    };
  }

})();
